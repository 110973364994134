import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { productsApi } from "./Api/ProductApi";
import { cartApi } from "./Api/CartApi";
import { companyInfoApi } from "./Api/CompanyInfoApi";
import { authApi } from "./Api/AuthApi";
import { wishlistApi } from "./Api/WishlistApi";
import { categoryApi } from "./Api/CategoryApi";
import { reviewApi } from "./Api/ReviewApi";
import { addressApi } from "./Api/AddressApi";
import { orderApi } from "./Api/OrderApi";
import { accountApi } from "./Api/AccountApi";
import { cmsPagesApi } from "./Api/CMSApi";
import { brandsApi } from "./Api/BrandApi";
import { thirdPartyAuthApi } from "./Api/ThirdPartyAuthApi";

export const store = configureStore({
  reducer: {
    [productsApi.reducerPath]: productsApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [companyInfoApi.reducerPath]: companyInfoApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [wishlistApi.reducerPath]: wishlistApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [reviewApi.reducerPath]: reviewApi.reducer,
    [addressApi.reducerPath]: addressApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [cmsPagesApi.reducerPath]: cmsPagesApi.reducer,
    [brandsApi.reducerPath]: brandsApi.reducer,
    [thirdPartyAuthApi.reducerPath]: thirdPartyAuthApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(productsApi.middleware)
      .concat(cartApi.middleware)
      .concat(companyInfoApi.middleware)
      .concat(authApi.middleware)
      .concat(wishlistApi.middleware)
      .concat(categoryApi.middleware)
      .concat(reviewApi.middleware)
      .concat(addressApi.middleware)
      .concat(orderApi.middleware)
      .concat(accountApi.middleware)
      .concat(cmsPagesApi.middleware)
      .concat(brandsApi.middleware)
      .concat(thirdPartyAuthApi.middleware),
});

setupListeners(store.dispatch);
