import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Cart, Heart } from "react-bootstrap-icons";
import ProductCardHook from "../../../hooks/product/ProductCardHook";
import { toast } from "react-toastify";
import defaultImage from "../../../assets/images/default-store-350x350.jpg";
import { Link } from "react-router-dom";
import TransHook from "./../../../hooks/translation/trans-hook";
import Cookies from "js-cookie";
import StarRatings from "react-star-ratings";
import calculateAndFormatPrice from "../../../hooks/GobalHooks.js/FormatPrice";

const ProductCard = ({ product }) => {
  const { handleCart, BuyNow, handleAddItem } = ProductCardHook();

  const { t } = TransHook();
  const lang = localStorage.getItem("language");
  const cookieValue = Cookies.get("CompanyCurrency");
  const currObj = cookieValue ? JSON.parse(cookieValue) : null;
  const [Pname, setPname] = useState("");
  const [price, setPrice] = useState("");
  useEffect(() => {
    if (product) {
      lang === "ar"
        ? setPname(product?.nameAR)
        : lang === "tr"
        ? setPname(product?.nameTR)
        : setPname(product?.name);
    }
  }, [product, lang]);
  useEffect(() => {
    if (product) {
      setPrice(
        calculateAndFormatPrice(
          product?.ecommercePrice,
          product?.ecommercePriceAftereDiscount,
          product?.currency?.exchangeRate
        )
      );
    }
  }, [product]);

  return (
    <div
      className={`product-default inner-quickview inner-icon p-4 ${
        lang === "ar" ? "arabic-text" : ""
      }`}
    >
      <figure>
        <Link to={`/product-details/${product?._id}`} style={{ width: "100%" }}>
          <img
            src={
              product.imagesArray && product?.imagesArray.length > 0
                ? product?.imagesArray[0]?.image
                : defaultImage
            }
            style={{ width: "100%", objectFit: "scale-down" }}
            alt="product"
          />
        </Link>
        <div
          className="btn-icon-group addToCart"
          onClick={() =>
            product?.activeCount > 0
              ? handleCart(product)
              : toast.error(t("itemNotAvailable"))
          }
        >
          <span className="btn-icon cursor-pointer product-type-simple ">
            <Cart />
          </span>
        </div>
        <span
          style={{ cursor: "pointer" }}
          className={`btn-quickview ${lang === "ar" ? "arabic-text" : ""}`}
          title="Buy now"
          onClick={() => BuyNow(product)}
        >
          {t("buyNow")}
        </span>
      </figure>
      <div className="product-details">
        <div className="category-wrap">
          <div
            className={`category-list ${
              lang === "ar" ? "arabic-text text-right" : ""
            }`}
          >
            {product?.category ? (
              <Link
                to={`/products/${product?.category?._id}/category`}
                className="product-category"
              >
                {product?.category?.name}
              </Link>
            ) : (
              <span className="product-category">{t("notCategorized")}</span>
            )}
          </div>

          <span>
            {/* TODO: Make this on the left of the card when in Arabic */}
            <Heart
              className="btn-icon-wish cursor-pointer"
              onClick={() => handleAddItem(product?._id)}
            />
          </span>
        </div>
        <div className="product-title">
          <Link to={`/product-details/${product?._id}`}>
            {Pname?.length > 150 ? Pname.substring(0, 150) + "..." : Pname}
          </Link>
        </div>
        <div className="ratings-container">
          <StarRatings
            rating={product?.ratingsAverage}
            starRatedColor="#fe9900"
            starEmptyColor="lightgray"
            numberOfStars={5}
            starDimension="15px"
            starSpacing=".5px"
          />
        </div>

        <div className="price-box">
          {/* <span
            className={`${
              product?.ecommercePriceAftereDiscount > 0 ? "old-price" : "d-none"
            }`}
          >
            {product?.ecommercePrice > 0
              ? product?.ecommercePrice?.toFixed(2)
              : product?.taxPrice.toFixed(2)}
          </span> */}
          <span className="product-price" style={{ fontSize: "20px" }}>
            {price}
            {currObj?.currencyCode}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
