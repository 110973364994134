import { SliderEndPoint } from "../../api/GlobalData";
import useGetData from "./../api/useGetData";

const OfferSliderHook = () => {
  const {
    data: sliderInfo,
    isLoading: sliderIsLoading,
    error: sliderError,
    refetch: refetchSlider,
  } = useGetData(SliderEndPoint);

  return { sliderInfo, sliderIsLoading, sliderError, refetchSlider };
};

export default OfferSliderHook;
