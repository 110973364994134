import { useEffect, useState } from "react";
import AddressesHook from "../profile/AddressesHook";
import { useAddOrderItemMutation } from "../../RTK/Api/OrderApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import GetCompanyInfo from "../company/getCompanyInfo";
import TransHook from "./../translation/trans-hook";

const CheckoutHook = (cartId) => {
  const navigate = useNavigate();
  const { t } = TransHook();
  const [selectShipAddr, setSelectShipAddr] = useState();
  const [selectBilAddr, setSelectBilAddr] = useState();
  const [activeShipIndex, setActiveShipIndex] = useState(null);
  const [activeBilIndex, setActiveBilIndex] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [signedIn] = useState(!!Cookies.get("userData"));

  useEffect(() => {
    if (signedIn) {
      const userDataString = Cookies.get("userData");
      const userData = JSON.parse(userDataString);
      setUserInfo(userData);
    }
  }, []);

  const { companyData } = GetCompanyInfo();

  const {
    addressGet,
    handleAliasChange,
    handleFirstNameChange,
    handleLastNameChange,
    handleCityChange,
    handleDetailsChange,
    handlePostalCodeChange,
    handlePhoneChange,
    alias,
    firstName,
    lastName,
    city,
    details,
    postalCode,
    phone,
    saveAddress,
    clearFields,
  } = AddressesHook();

  const [addingAddress, setAddingAddress] = useState(false);
  const [differentBilling, setDifferentBilling] = useState(false);

  const cancel = () => {
    setAddingAddress(!addingAddress);
    clearFields();
  };

  const save = () => {
    saveAddress();
    setAddingAddress(!addingAddress);
    clearFields();
  };

  const [cart, setCart] = useState();
  useEffect(() => {
    const cartJson = JSON.parse(localStorage.getItem("cart"));
    if (cartJson) setCart(cartJson);
  }, []);

  const selectShipAddress = (i, address) => {
    setActiveShipIndex(i);
    setSelectShipAddr(address);
    if (!selectBilAddr) setSelectBilAddr(address);
  };

  const selectBilAddress = (i, address) => {
    setActiveBilIndex(i);
    setSelectBilAddr(address);
  };

  const [addOrderItem] = useAddOrderItemMutation();

  const createFullAddress = (address) => {
    const { name, ...rest } = address;
    return {
      ...rest,
      name: name,
    };
  };

  const createOrder = async (method) => {
    try {
      const shippingAddress = createFullAddress(selectShipAddr);
      const billingAddress = createFullAddress(selectBilAddr);

      const ipAddress = await getIP();

      const body = {
        shippingAddress,
        billingAddress,
        ipAddress,
        userInfo,
      };

      const paymentContext = await addOrderItem({ id: cartId, body }).unwrap();
      if (method === "creditCard") {
        localStorage.setItem("order", JSON.stringify(paymentContext));
        navigate("/payment");
      } else {
        window.location.href = `https://wa.me/${companyData?.companyTel}/?text=I%20want%20to%20confirm%20my%20order:%20${cartId}`;
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message || t("errorOccurred"));
    }
  };

  const getIP = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null;
    }
  };

  const success = () => {
    toast.success(t("orderCompleted"));
    localStorage.removeItem("cart");
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  return {
    addressGet,
    activeShipIndex,
    activeBilIndex,
    addingAddress,
    alias,
    firstName,
    lastName,
    city,
    details,
    postalCode,
    phone,
    cart,
    differentBilling,
    handleAliasChange,
    handleFirstNameChange,
    handleLastNameChange,
    handleCityChange,
    handleDetailsChange,
    handlePostalCodeChange,
    handlePhoneChange,
    save,
    cancel,
    createOrder,
    setDifferentBilling,
    setAddingAddress,
    selectShipAddress,
    selectBilAddress,
  };
};

export default CheckoutHook;
