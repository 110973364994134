import React from "react";
import "./HomePage.css";
import ProductSlider from "../utility/Slider/Product-Slider";
import { useGetFeaturedProductsQuery } from "../../RTK/Api/ProductApi";
import { toast } from "react-toastify";
import TransHook from "../../hooks/translation/trans-hook";

const FeaturedProducts = () => {
  const { data, error } = useGetFeaturedProductsQuery();
  if (error) toast.error(error);
  const screen = localStorage.getItem("screen") || "large";

  const { t } = TransHook();
  const lang = localStorage.getItem("language");

  if (data?.data?.length > 0) {
    return (
      <div className="FeaturedProducts">
        <h2
          className={`section-title ls-n-10 pb-3 m-b-4  ${
            lang === "ar" ? "arabic-text" : ""
          }`}
          data-animation-name="fadeInUpShorter"
          data-animation-delay="200"
        >
          {t("featuredProducts")}
        </h2>

        <div className="row featured-products">
          <ProductSlider
            slidesPerView={screen === "large" ? 4 : 2}
            Array={data?.data}
          />
        </div>
      </div>
    );
  }
};

export default FeaturedProducts;
