import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "./../../components/utility/navbar/navbar";
import Footer from "../../components/utility/Footer/Footer";
import parse from "html-react-parser";
import { ToastContainer } from "react-bootstrap";
import { toast } from "react-toastify";
import { useGetCmsPageByIdQuery } from "../../RTK/Api/CMSApi";
import Loader from "../../components/Loaders/Loader";

const CMSPage = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useGetCmsPageByIdQuery(id);
  if (error) toast.error(error);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <>
        <Navbar />
        <div
          className="text-center"
          style={{
            marginTop: "12%",
            marginLeft: "100px",
            marginRight: "100px",
          }}
        >
          <h1 className="mb-3">{data?.data?.title}</h1>
          <p>{parse(data?.data?.description)}</p>
        </div>
        <Footer />
        <ToastContainer autoClose={2000} />
      </>
    );
  }
};

export default CMSPage;
