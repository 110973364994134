const baseURL = "https://demo.smartinb.ai";
export const DataBaseName = "noontek_gaziantep";
export const LogInEndPoint = "/api/auth/ecommerce-login";
export const SignUpEndPoint = "/api/auth/signup";
export const ForgotPasswordEndPoint = "/api/auth/forgotPasswords";
export const ResetPasswordEndPoint = "/api/auth/resetPassword";
export const CategoriesEndPoint = "/api/category";
export const BrandsEndPoint = "/api/brand";
export const CartEndPoint = "/api/cart";
export const ProductsEndPoint = "/api/product";
export const WishlistEndPoint = "/api/wishlist";
export const CompanyInfoEndPoint = "/api/companyinfo";
export const AddressEndPoint = "/api/addresses";
export const UpdatePasswordEndPoint = "/api/customars/updatePassword";
export const UpdateAccountEndPoint = "/api/customars/e-edit";
export const OrderEndPoint = "/api/ecommerce-order-router";
export const CmsPagesEndPoint = "/api/page";
export const ReviewEndPoint = "/api/review";
export const LazyProductEndPoint = "/api/product/productLazy";
export const SliderEndPoint = "/api/slider";
export const ThirdPartyAuthEndPoint = "/api/thirdPartyAuth";
export const UsersEndPoint = "/api/users";
export default baseURL;

//http://ec2-13-48-42-140.eu-north-1.compute.amazonaws.com
