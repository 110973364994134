import React, { useCallback } from "react";
import ProductCard from "./ProductCard";
import { toast } from "react-toastify";
import TransHook from "../../../hooks/translation/trans-hook";
import { XSquareFill } from "react-bootstrap-icons";
import "../../../pages/Product/Products.css";

const ProductsContainer = ({
  productList,
  error,
  handleSortChange,
  brands,
  setBrands,
  priceRange,
  setPriceRange,
  ratingRange,
  setRatingRange,
  handleLoadMoreClick,
}) => {
  const { t } = TransHook();
  const lang = localStorage.getItem("language");

  const handleSort = useCallback(
    (e) => {
      handleSortChange(e.target.value);
    },
    [handleSortChange]
  );

  if (error) toast.error(error);

  const screen = localStorage.getItem("screen") || "large";

  return (
    <div
      className={`col-lg-9 main-content ${lang === "ar" ? "arabic-text" : ""}`}
      style={{ minHeight: "840px" }}
    >
      <nav
        className="toolbox sticky-header"
        style={{ width: "100%", marginBottom: "30px" }}
        data-sticky-options="{'mobile': true}"
      >
        <div className="SortBy">
          <label>{t("sortBy")}:</label>
          <select name="orderby" className="form-control" onChange={handleSort}>
            <option value="default">{t("defaultSorting")}</option>
            <option value="rating">{t("highestRated")}</option>
            <option value="date">{t("newest")}</option>
            <option value="price">{t("priceLowHigh")}</option>
            <option value="price-desc">{t("priceHighLow")}</option>
          </select>
        </div>

        {/* {screen === "small" && (
          <SideNavMobile
            categories={categories}
            brands={brands}
            products={allProducts}
            setFilteredProducts={setProductList}
            handleFilterByPrice={handleFilterByPrice}
          />
        )} */}
      </nav>
      <div className="Filters_list">
        {brands?.length > 0 || priceRange || ratingRange ? (
          <ul>
            {/* Display selected brand names */}
            {brands?.length > 0 &&
              brands?.map((brand, index) => <li key={index}>{brand}</li>)}

            {/* Display selected price range */}
            {priceRange?.from && (
              <li>
                {priceRange.from} - {priceRange.to}
                <XSquareFill onClick={() => setPriceRange({})} />
              </li>
            )}

            {/* Display selected rating range */}
            {ratingRange.rateMin && (
              <li>
                {ratingRange.rateMin} - {ratingRange.rateMax} stars
                <XSquareFill onClick={() => setRatingRange({})} />
              </li>
            )}
          </ul>
        ) : (
          <p>No filters selected.</p>
        )}
      </div>
      <div className="row search-products">
        {productList?.map((product, index) => (
          <div
            key={index}
            className={`${screen === "large" ? "col-4" : "col-6"}`}
            id={product?._id}
          >
            <ProductCard product={product} />
          </div>
        ))}
      </div>
      <button className="LoadProductsButton" onClick={handleLoadMoreClick}>
        Load More Products
      </button>
    </div>
  );
};

export default ProductsContainer;
