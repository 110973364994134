import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseURL, {
  DataBaseName,
  ForgotPasswordEndPoint,
  LogInEndPoint,
  ResetPasswordEndPoint,
  SignUpEndPoint,
} from "../../api/GlobalData";
import Cookies from "js-cookie";

const jwt = Cookies.get("Token");

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers) => {
      if (jwt) {
        headers.set("Authorization", `Bearer ${jwt}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Auth"], // Define the tag type
  endpoints: (builder) => ({
    logIn: builder.mutation({
      query: (logInData) => ({
        url: `${LogInEndPoint}?databaseName=${DataBaseName}`,
        method: "POST",
        body: logInData,
      }),
      invalidatesTags: ["Auth"],
    }),
    SignUp: builder.mutation({
      query: (SignUpData) => ({
        url: `${SignUpEndPoint}?databaseName=${DataBaseName}`,
        method: "POST",
        body: SignUpData,
      }),
      invalidatesTags: ["Auth"],
    }),

    ForgotPassword: builder.mutation({
      query: (email) => ({
        url: `${ForgotPasswordEndPoint}?databaseName=${DataBaseName}`,
        method: "POST",
        body: email,
      }),
    }),
    ResetPassword: builder.mutation({
      query: ({ newPassword, token }) => ({
        url: `${ResetPasswordEndPoint}?databaseName=${DataBaseName}&token=${token}`,
        method: "PUT",
        body: { newPassword },
      }),
    }),
  }),
});

export const {
  useLogInMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi;
