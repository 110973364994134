import React, { useEffect, useState } from "react";
import LoginImg from "../../assets/images/afaqs_2022-09_3e27442e-a2a2-4b93-913c-a2ff85046fb2_Untitled.webp";
import "./Auth.css";
import AuthHook from "../../hooks/Auth/AuthHook";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import TransHook from "./../../hooks/translation/trans-hook";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleLoginButton from "./GoogleLogin";
import FaccbookLoginButton from "./FaccbookLoginButton";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";

function LoginForm() {
  const {
    handleLogIn,
    handleLogInChange,
    handleSignUp,
    handleSignUpChange,
    logInIsLoading,
    logInError,
    signUpIsLoading,
    signUpError,
    companyLogo,
    googleAuth,
    facebookAuth,
    facebookAppId,
    googleClientId,
  } = AuthHook();

  const [isFlipped, setIsFlipped] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleSignUpClick = () => {
    setIsFlipped(!isFlipped);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") handleLogIn();
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleLogIn]);
  const { t } = TransHook();
  const lang = localStorage.getItem("language");
  const isRTL = lang === "ar";
  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div className={`AuthContainer ${lang === "ar" ? "arabic-text" : ""}`}>
      <div className="Auth d-flex row ">
        <div
          className={`col-6 AuthImg ${
            isRTL ? (isFlipped ? "flip-Form" : "") : isFlipped ? "flip-Img" : ""
          }`}
        >
          <img src={LoginImg} alt="LoginImg" />
        </div>
        <div
          className={`col-6 AuthForm ${
            isRTL ? (isFlipped ? "flip-Img" : "") : isFlipped ? "flip-Form" : ""
          }`}
        >
          <img src={companyLogo} alt="Logo" className="Logo" />
          {isFlipped === false ? (
            <form className="SignInForm">
              <div className="Sign_header">
                <h1>{t("signIn")}</h1>
                <button onClick={handleSignUpClick}>sign up</button>
              </div>
              <input
                type="email"
                className="form-control"
                aria-describedby="emailHelp"
                onChange={handleLogInChange}
                name="email"
                placeholder={t("email")}
              />

              <div className="password_con d-flex">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control col-9"
                  onChange={handleLogInChange}
                  name="password"
                  placeholder={t("password")}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    toggleShowPassword();
                  }}
                  className="col-3"
                >
                  {showPassword ? <EyeSlashFill /> : <EyeFill />}
                </button>
              </div>

              {logInError && (
                <div className="error-message">{t("incorrectCredits")}</div>
              )}
              <div className="Login_buttons">
                <button
                  type="submit"
                  className="confirm_signup col-6"
                  onClick={handleLogIn}
                >
                  {logInIsLoading ? t("signingIn") : t("signIn")}
                </button>
                {googleAuth && (
                  <GoogleOAuthProvider clientId={googleClientId}>
                    <GoogleLoginButton />
                  </GoogleOAuthProvider>
                )}
                {facebookAuth && (
                  <FaccbookLoginButton facebookAppId={facebookAppId} />
                )}
              </div>

              <Link
                to={"/forgot-password"}
                style={{
                  display: "block",
                  position: "absolute",
                  bottom: "5px",
                  left: "39%",
                }}
              >
                {t("forgotPassword")}
              </Link>
            </form>
          ) : (
            <div className="SignInForm">
              <div className="Sign_header">
                <h1>{t("signUp")}</h1>
                <button onClick={handleSignUpClick}>Log in</button>
              </div>

              <input
                type="email"
                className="form-control"
                aria-describedby="emailHelp"
                placeholder={t("email")}
                onChange={handleSignUpChange}
                name="email"
              />

              <input
                type="password"
                className="form-control"
                placeholder={t("password")}
                onChange={handleSignUpChange}
                name="password"
              />

              {signUpError && (
                <div className="error-message">
                  {t("emailAlreadyRegistered")}
                </div>
              )}

              <div className="Login_buttons">
                <button
                  onClick={handleSignUp}
                  type="submit"
                  className="confirm_signup col-6"
                >
                  {signUpIsLoading ? t("signingUp") : t("signUp")}
                </button>
                {googleAuth && (
                  <GoogleOAuthProvider clientId={googleClientId}>
                    <GoogleLoginButton />
                  </GoogleOAuthProvider>
                )}
                {facebookAuth && (
                  <FaccbookLoginButton facebookAppId={facebookAppId} />
                )}
              </div>

              <p className="conditions">
                Kişisel verileriniz, Aydınlatma Metni kapsamında işlenmektedir.
                “Devam et” veya “Sosyal Hesap” butonlarından birine basarak
                Üyelik Sözleşmesi’ni ve Gizlilik Politikası’nı okuduğunuzu ve
                kabul ettiğinizi onaylıyorsunuz.
              </p>
            </div>
          )}

          <div className="links">
            <span>smart@2024</span>
            <div>
              <Link to={""}> Terms of use |</Link>
              <Link to={""}> Privacy Policy</Link>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
}

export default LoginForm;
