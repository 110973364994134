import React from "react";
import ReactDOM from "react-dom/client";
import "../src/components/utility/Slider/Slider.css";
import "../src/assets/style/main.css";
import "../src/assets/style/style.css";
import "../src/assets/style/bootstrap.min.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { store } from "./RTK/store";
import App from "./App";
import "./hooks/translation/i18n";
import { Provider } from "react-redux";
import "./index.css";

const queryClient = new QueryClient({});

const language = localStorage.getItem("language" || "en");
const direction = language === "ar" ? "rtl" : "ltr";

const rootElement = document.getElementById("root");
rootElement.setAttribute("dir", direction);

const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>
);
