import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseURL, { CmsPagesEndPoint, DataBaseName } from "../../api/GlobalData";

export const cmsPagesApi = createApi({
  reducerPath: "cmsPagesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
  }),
  tagTypes: ["CmsPages"],
  endpoints: (builder) => ({
    getCmsPageById: builder.query({
      query: (id) => `${CmsPagesEndPoint}/${id}?databaseName=${DataBaseName}`,
      providesTags: ["CmsPages"],
    }),
    getCmsPages: builder.query({
      query: () => `${CmsPagesEndPoint}?databaseName=${DataBaseName}`,
      providesTags: ["CmsPages"],
    }),
  }),
});

export const { useGetCmsPageByIdQuery, useGetCmsPagesQuery } = cmsPagesApi;
