import { Button, Modal } from "react-bootstrap";
import React from "react";
import { ArrowLeft, Star, StarFill } from "react-bootstrap-icons";
import Rating from "react-rating";
import { useGetOrderInfoQuery } from "../../RTK/Api/OrderApi";
import { Link } from "react-router-dom";
import "./OrderDetails.css";
import delivered from "../../assets/images/icons/delivered.png";
import truck from "../../assets/images/icons/truck.png";
import mail from "../../assets/images/icons/mail.png";
import openbox from "../../assets/images/icons/openbox.png";
import cancelled from "../../assets/images/icons/cancelled.png";
import processed from "../../assets/images/icons/processed.png";
import returned from "../../assets/images/icons/returned.png";
import { toast } from "react-toastify";
import Loader from "./../Loaders/Loader";
import OrderDetailsHook from "../../hooks/Order/OrderDetailsHook";
import TransHook from "../../hooks/translation/trans-hook";

const OrderDetails = ({ order, setOrder }) => {
  const { data, isLoading, error } = useGetOrderInfoQuery(order?._id);
  if (error) toast.error(error);

  const {
    show,
    prodName,
    statusOrder,
    showConfirm,
    confirmStatus,
    setRating,
    handleClose,
    handleReviewChange,
    handleShow,
    handleSubmit,
    getStatusClass,
    handleShowConfirm,
    handleCloseConfirm,
    handleRequest,
  } = OrderDetailsHook(order);

  const { t } = TransHook();
  const lang = localStorage.getItem("language");

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <>
        <h3
          style={{
            textAlign: "start",
            cursor: "pointer",
            width: "max-content",
          }}
          onClick={() => setOrder("")}
          className={lang === "ar" ? "arabic-text" : ""}
        >
          <ArrowLeft /> {t("backToOrders")}
        </h3>
        <div
          className={`details-container ${lang === "ar" ? "arabic-text" : ""}`}
        >
          <div className="d-flex justify-content-around">
            <div className="order-address-container ms-0">
              <h3 className="text-info">
                <span>{t("deliveryInformation")}</span>
              </h3>
              <p style={{ margin: "5px 0" }}>
                {order?.shippingAddress?.fullName}
              </p>
              <p style={{ margin: "5px 0" }}>
                {order?.shippingAddress?.details}
              </p>
              <p style={{ margin: "5px 0" }}>
                {order?.shippingAddress?.postalCode} /{" "}
                {order?.shippingAddress?.city}
              </p>
            </div>
            <div className="order-address-container me-0">
              <h3 className="text-info">
                <span>{t("billInformation")}</span>
              </h3>
              <p style={{ margin: "5px 0" }}>
                {order?.billingAddress?.fullName}
              </p>
              <p style={{ margin: "5px 0" }}>
                {order?.billingAddress?.details}
              </p>
              <p style={{ margin: "5px 0" }}>
                {order?.billingAddress?.postalCode} /{" "}
                {order?.billingAddress?.city}
              </p>
            </div>
          </div>
        </div>

        {data?.data?.cartItems?.map((item, i) => {
          const status = item?.orderStatus?.toLowerCase();
          return (
            <div
              className={`details-container ${
                lang === "ar" ? "arabic-text" : ""
              }`}
              key={i}
            >
              <div className="inner-container">
                <div className="content-container">
                  <div className="steps-container">
                    {statusOrder
                      .filter(
                        (stepStatus) =>
                          (!["cancelled", "returned", "not delivered"].includes(
                            stepStatus
                          ) ||
                            stepStatus === status) &&
                          !(
                            ["cancelled", "returned", "not delivered"].includes(
                              status
                            ) && stepStatus === "delivered"
                          )
                      )
                      .map((stepStatus) => (
                        <div className="step" key={stepStatus}>
                          <img
                            src={
                              {
                                requested: mail,
                                approved: processed,
                                processed: openbox,
                                shipped: truck,
                                cancelled: cancelled,
                                returned: returned,
                                "not delivered": cancelled,
                                delivered: delivered,
                              }[stepStatus]
                            }
                            className={`img ${getStatusClass(
                              status,
                              stepStatus
                            )}`}
                            alt={stepStatus}
                          />
                          <div className="step-title">{t(stepStatus)}</div>
                        </div>
                      ))}
                  </div>
                  {status === "delivered" || status === "returned" ? (
                    <div className="delivery-container">
                      <div className="delivery-date-container">
                        <div className="delivery-title">
                          {t("deliveryDate")}:
                        </div>
                        <div className="delivery-date">25/05/2024 - 16:43</div>
                      </div>
                      <div className="delivered-container">
                        <div className="delivered-title">
                          {t("deliveredTo")}:
                        </div>
                        <div className="delivered-name">
                          {order?.shippingAddress?.fullName}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="order-product-container">
                    <img
                      loading="lazy"
                      srcSet={item?.product?.image}
                      className="order-product-img"
                    />
                    <div className="order-product-details">
                      <div className="product-title">
                        <Link to={`/product-details/${item?.product?._id}`}>
                          {item?.product?.name}
                        </Link>
                      </div>
                      <div className="product-order-details mt-2">
                        <div>
                          <h4>{t("productCode")}:&nbsp;</h4>
                          <p>{item.product?.qr}</p>
                        </div>
                        <div>
                          <h4>{t("quantity")}:&nbsp;</h4>
                          <p>{item?.quantity}</p>
                        </div>
                        <div>
                          <h4>{t("totalPrice")}:&nbsp;</h4>
                          <p>${item?.quantity * (item?.taxPrice).toFixed(3)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="actions-container">
                  {status === "delivered" ? (
                    <>
                      <div
                        className="rate-button"
                        onClick={() =>
                          handleShow(item?.product?._id, item?.product?.name)
                        }
                      >
                        {t("rate")}
                      </div>
                      <div
                        className="return-cancel-button"
                        onClick={() => handleShowConfirm(item._id, "returned")}
                      >
                        {t("returnProduct")}
                      </div>
                    </>
                  ) : null}
                  {status !== "delivered" &&
                  status !== "cancelled" &&
                  status !== "returned" ? (
                    <div
                      className="return-cancel-button"
                      onClick={() => handleShowConfirm(item._id, "cancelled")}
                    >
                      {t("cancelProduct")}
                    </div>
                  ) : null}

                  <div
                    className={`invoice-button ${
                      status === "cancelled" || status === "returned"
                        ? "disabled"
                        : ""
                    }`}
                    onClick={() => console.log(";)")}
                  >
                    {t("invoice")}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {/* Review modal START */}
        <Modal
          show={show}
          onHide={handleClose}
          className={lang === "ar" ? "arabic-text" : ""}
        >
          <Modal.Header>
            <Modal.Title>
              <h3>
                {t("rate")} <b>{prodName}</b>
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="add-product-review">
              <h3 className="review-title">{t("addReview")}</h3>

              <form action="#" className="comment-form m-0">
                <div className="rating-form">
                  <label for="rating">
                    {t("yourRating")} <span className="required">*</span>
                  </label>
                  <span className="rating-stars">
                    <Rating
                      emptySymbol={<Star />}
                      fullSymbol={<StarFill />}
                      onChange={(value) => setRating(value)}
                    />
                  </span>
                </div>

                <div className="form-group">
                  <label>
                    {t("yourReview")} <span className="required">*</span>
                  </label>
                  <textarea
                    cols="5"
                    onChange={handleReviewChange}
                    rows="6"
                    className="form-control form-control-sm"
                  />
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="trinary" onClick={handleClose}>
              {t("cancel")}
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              {t("save")}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Review modal END */}

        {/* Cancel/return confirmation modal START */}
        <Modal show={showConfirm} onHide={handleCloseConfirm}>
          <Modal.Header>
            <Modal.Title>
              <h2>{t("caution")}</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="add-product-review">
              <h3>
                {t("productStatusConfirm")} {confirmStatus}?
              </h3>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="trinary" onClick={handleCloseConfirm}>
              {t("cancel")}
            </Button>
            <Button variant="primary" onClick={handleRequest}>
              {t("save")}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Cancel/return confirmation modal END */}
      </>
    );
  }
};

export default OrderDetails;
