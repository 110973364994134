import { useGoogleLogin } from "@react-oauth/google";
import baseURL, { DataBaseName } from "../../api/GlobalData";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import GoogleLogo from "../../assets/images/google.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const GoogleLoginButton = () => {
  const navigate = useNavigate();
  const [cookiesSet, setCookiesSet] = useState(false);

  const handleLoginSuccess = async (response) => {
    console.log(response);
    const code = response.code;
    try {
      const res = await axios.post(
        `${baseURL}/api/auth/google-signin?databaseName=${DataBaseName}`,
        {
          code,
        }
      );
      if (res) {
        console.log(response);
        Cookies.set("Token", res.data.token, { expires: 7 });
        const userData = res?.data?.user;
        const userDataString = JSON.stringify(userData);
        Cookies.set("userData", userDataString, { expires: 7 });
        setCookiesSet(true);
      }
    } catch (error) {
      console.error("Login Error", error);
    }
  };

  // Navigate after cookies are set
  useEffect(() => {
    if (cookiesSet) {
      navigate("/");
    }
  }, [cookiesSet, navigate]);

  const handleLoginFailure = (error) => {
    console.error("Login Failed", error);
  };

  const login = useGoogleLogin({
    onSuccess: handleLoginSuccess,
    onError: handleLoginFailure,
    scope: "openid email profile", // Ensure openid scope is used
    prompt: "consent", // Optionally ask for consent every time
    responseType: "id_token", // Explicitly request the id_token
    flow: "auth-code",
  });

  return (
    // <div>
    //   <GoogleLogin
    //     onSuccess={handleLoginSuccess}
    //     onError={handleLoginFailure}
    //   />
    // </div>
    <button
      className="custom-google-login-button col-3"
      onClick={(event) => {
        event.preventDefault();
        login();
      }}
    >
      <img src={GoogleLogo} alt="Google" className="GLogo" />
    </button>
  );
};

export default GoogleLoginButton;
