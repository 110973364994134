import { useTranslation } from "react-i18next";

const TransHook = () => {
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("language");

  if (!lang) localStorage.setItem("language", "en");

  const changeEn = () => {
    i18n.changeLanguage("en");
    localStorage.setItem("language", "en");
  };

  const changeAr = () => {
    i18n.changeLanguage("ar");
    localStorage.setItem("language", "ar");
  };

  const changeTr = () => {
    i18n.changeLanguage("tr");
    localStorage.setItem("language", "tr");
  };

  return { changeAr, changeEn, changeTr, t };
};

export default TransHook;
