import React, { useEffect, useState } from "react";

const ProductDetailSlider = ({ slides, Array: ProductData }) => {
  const [images, setImages] = useState();
  useEffect(() => {
    if (ProductData) {
      setImages(ProductData?.imagesArray);
    }
  }, [ProductData]);

  return (
    <div className="productDetailSlider">
      {/* Main image */}
      <swiper-container
        id="mySwiper"
        className="mySwiper"
        thumbs-swiper="#mySwiper2"
        loop="true"
        navigation="true"
      >
        {images?.map((item, i) => (
          <swiper-slide key={i}>
            <img
              src={item.image}
              width={570}
              height={700}
              loading="lazy"
              alt={`Slide ${i}`}
            />
          </swiper-slide>
        ))}
      </swiper-container>

      {/* Thumbnails */}
      {images?.length > 1 ? (
        <swiper-container
          className="mySwiper2"
          id="mySwiper2"
          loop="true"
          space-between="10"
          slides-per-view={slides}
          watch-slides-progress="true"
        >
          {images?.map((item, i) => (
            <swiper-slide
              id="swiper2"
              key={i}
              onClick={() => {
                const swiper = document.getElementById("mySwiper").swiper;
                swiper.slideTo(i, 300);
              }}
            >
              <img
                className="cursor-pointer"
                loading="lazy"
                src={item.image}
                alt={`Thumbnail ${i}`}
              />
            </swiper-slide>
          ))}
        </swiper-container>
      ) : null}
    </div>
  );
};

export default ProductDetailSlider;
