import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SideNav from "../../components/utility/SideNav/SideNav";
import ProductsContainer from "../../components/Product/JSX/ProductsContainer";
import Navbar from "../../components/utility/navbar/navbar";
import Footer from "../../components/utility/Footer/Footer";
import "./Products.css";
import { ToastContainer } from "react-toastify";
import ProductsPageHook from "../../hooks/product/ProductsPageHook";

const Products = () => {
  const { id, type, keyword } = useParams();
  const screen = localStorage.getItem("screen") || "large";
  const {
    productList,
    isLoading,
    error,
    categories,
    brands,
    loadingMore,
    handleSortChange,
    setBrandId,
    setTaxPriceMin,
    seTaxPriceMax,
    setMaxAvg,
    setMinAvg,
    handleLoadMoreClick,
  } = ProductsPageHook(id, type, keyword);
  const [selectedBrandNames, setSelectedBrandNames] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState({});
  const [ratingRange, setRatingRange] = useState({});

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="Products row">
          <ProductsContainer
            productList={productList}
            isLoading={isLoading}
            error={error}
            handleSortChange={handleSortChange}
            brands={selectedBrandNames}
            setBrands={setSelectedBrands}
            priceRange={selectedPriceRange}
            setPriceRange={setSelectedPriceRange}
            ratingRange={ratingRange}
            setRatingRange={setRatingRange}
            handleLoadMoreClick={handleLoadMoreClick}
          />
          {screen === "large" && (
            <SideNav
              categories={categories}
              brands={brands}
              products={productList}
              setBrandsArray={setBrandId}
              setMaxPrice={seTaxPriceMax}
              setMinPrice={setTaxPriceMin}
              setMaxAvg={setMaxAvg}
              setMinAvg={setMinAvg}
              selectedBrands={selectedBrands}
              setSelectedBrands={setSelectedBrands}
              setSelectedBrandNames={setSelectedBrandNames}
              selectedPriceRange={selectedPriceRange}
              setSelectedPriceRange={setSelectedPriceRange}
              ratingRange={ratingRange}
              setRatingRange={setRatingRange}
            />
          )}
        </div>
      </div>

      <Footer />
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default Products;
