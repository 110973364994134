import axios from "axios";
import React, { useState } from "react";
import baseURL, { DataBaseName } from "../../api/GlobalData";
import Cookies from "js-cookie";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacbookLogo from "../../assets/images/facebook.png";

const FaccbookLoginButton = ({ facebookAppId }) => {
  const [cookiesSet, setCookiesSet] = useState(false);

  const handleFacebookCallback = async (response) => {
    if (response?.status === "unknown") {
      console.error("Sorry!", "Something went wrong with Facebook login");
      return;
    }
    const { accessToken, userID } = response;
    const res = await axios.post(
      `${baseURL}/api/auth/facebook-signin?databaseName=${DataBaseName}`,
      {
        accessToken,
        userID,
      }
    );
    if (res) {
      Cookies.set("Token", res.data.token, { expires: 7 });
      const userData = res?.data?.user;
      const userDataString = JSON.stringify(userData);
      Cookies.set("userData", userDataString, { expires: 7 });
      setCookiesSet(true);
    } else console.error("no res");
  };

  return (
    <div className="col-3">
      <FacebookLogin
        appId={facebookAppId}
        fields="name,email"
        scope="public_profile"
        callback={handleFacebookCallback}
        render={(renderProps) => (
          <img
            onClick={renderProps.onClick}
            src={FacbookLogo}
            className="fb"
            alt="Facebook"
            style={{ cursor: "pointer", width: "85%" }}
          />
        )}
        autoLoad={false}
      />
    </div>
  );
};

export default FaccbookLoginButton;
