import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useAddReviewItemMutation } from "../../RTK/Api/ReviewApi";

const ReviewHook = () => {
  const [show, setShow] = useState(false);
  const [prodId, setProdId] = useState();
  const [prodName, setProdName] = useState();
  const [rating, setRating] = useState(1);
  const [review, setReview] = useState();
  const [userInfo, setUserInfo] = useState({});

  const [addReview] = useAddReviewItemMutation();

  const handleClose = () => setShow(false);
  const handleReviewChange = (event) => setReview(event.target.value);
  const handleShow = (id, name) => {
    setProdId(id);
    setProdName(name);
    setShow(true);
  };

  useEffect(() => {
    const userDataString = Cookies.get("userData");
    const userData = JSON.parse(userDataString);
    setUserInfo(userData);
  }, []);

  const formData = {
    review,
    rating,
    product: prodId,
    customar: userInfo._id,
  };

  const handleSubmit = () => {
    console.log(formData);

    addReview(formData);
    handleClose();
  };

  return {
    show,
    prodName,
    setRating,
    handleClose,
    handleReviewChange,
    handleShow,
    handleSubmit,
  };
};

export default ReviewHook;
