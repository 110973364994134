import React from "react";
import { Dash, Plus, Trash2Fill } from "react-bootstrap-icons";
import Loader from "./../Loaders/Loader";
import { toast } from "react-toastify";
import "./ShoppingCart.css";
import CartHook from "../../hooks/cart/CartHook";
import { Link } from "react-router-dom";
import TransHook from "./../../hooks/translation/trans-hook";

const ShoppingCart = ({ proceedToCheckout }) => {
  const {
    isCartLoading,
    cart,
    cartTotal,
    handleAddToCart,
    handleDecreaseCart,
    handleRemoveItem,
    submitCart,
  } = CartHook(proceedToCheckout);

  const { t } = TransHook();
  const lang = localStorage.getItem("language");

  if (isCartLoading) {
    return <Loader />;
  } else {
    return (
      <div
        className={`row shopping_cart ${lang === "ar" ? "arabic-text" : ""}`}
      >
        {/* Cart items START */}
        <div className="col-lg-8">
          {cart?.data?.cartItems?.map((item, i) => {
            return (
              <div key={i} className="d-flex cart-item-container">
                <div style={{ width: "20%", margin: "10px" }}>
                  <Link to={`/product-details/${item?.product}`}>
                    <img src={item?.image} alt={item?.name?.split(" ")[0]} />
                  </Link>
                </div>

                <div style={{ width: "40%" }}>
                  <h3>
                    <Link
                      to={`/product-details/${item?.product}`}
                      className="fw-bold text-dark"
                      title={item?.name}
                    >
                      {item?.name?.length > 30
                        ? item?.name.slice(0, 30) + "..."
                        : item?.name}
                    </Link>
                  </h3>
                  <p>
                    {t("productCode")}: {item?.qr}
                  </p>
                </div>
                <div
                  className="price"
                  style={{
                    width: "20%",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Plus
                    className="cursor-pointer"
                    size={25}
                    onClick={() =>
                      item?.maxQuantity > item?.quantity
                        ? handleAddToCart(item)
                        : toast.error(t("cantAddMore"))
                    }
                    color="#fe9900"
                  />
                  <p className="d-flex cart-item-quantity">{item?.quantity}</p>
                  <Dash
                    className={`cursor-pointer ${
                      item?.quantity > 1 ? "" : "cursor-not-allowed"
                    } `}
                    size={25}
                    color="#fe9900"
                    onClick={
                      item?.quantity > 1
                        ? () => handleDecreaseCart(item)
                        : () => toast.error(t("cantDecrese"))
                    }
                  />
                </div>
                <div style={{ width: "20%" }}>
                  <div
                    className="Trash"
                    onClick={() => handleRemoveItem(item?._id)}
                  >
                    <Trash2Fill />
                  </div>
                  <h3 className="text-center mt-2">
                    <span style={{ color: "#fe9900" }}>
                      ${(item?.taxPrice * item?.quantity).toFixed(3)}
                    </span>
                    <h5>
                      {(item?.taxPrice).toFixed(3)} × {item?.quantity}
                    </h5>
                  </h3>
                </div>
              </div>
            );
          })}
        </div>
        {/* Cart items END */}

        {/* Cart totals and shipping START */}
        <div className="col-lg-4">
          <div className="cart-summary">
            <h3>{t("cartTotal")}</h3>

            <table className="table table-totals">
              <tbody>
                <tr>
                  <td>{t("total")}</td>
                  <td>{cartTotal?.toFixed(3)}</td>
                </tr>
              </tbody>
            </table>

            <div className="checkout-methods">
              <button
                disabled={cartTotal <= 0}
                type="submit"
                className="btn btn-block btn-dark"
                onClick={submitCart}
              >
                {t("proceedToCheckout")}
              </button>
            </div>
          </div>
        </div>
        {/* Cart totals and shipping END */}
      </div>
    );
  }
};

export default ShoppingCart;
