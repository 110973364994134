import React from "react";
import HomeCategories from "../../components/Home/HomeCategories";
import ProductsNewArrivals from "../../components/Home/products-new-arrivals";
import FeaturedProducts from "../../components/Home/FeaturedProducts";
import TopSellingProducts from "../../components/Home/TopSellingProducts";
import OfferSlider from "../../components/utility/Slider/offerSlider";
import Brands from "../../components/Home/Brands";
import Navbar from "../../components/utility/navbar/navbar";
import Footer from "../../components/utility/Footer/Footer";
import { ToastContainer } from "react-toastify";
import HomeSlider from "../../components/utility/Slider/HomeSlider";
import SponsoredProducts from "../../components/Home/SponsoredProducts";

const HomePage = () => {
  return (
    <>
      <Navbar />
      <div className="container">
        <HomeSlider />
        <HomeCategories />
        <ProductsNewArrivals />
        <OfferSlider />
        <FeaturedProducts />
        <TopSellingProducts />
        <SponsoredProducts />
        <Brands />
      </div>
      <ToastContainer autoClose={2000} />
      <Footer />
    </>
  );
};

export default HomePage;
