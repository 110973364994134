import React, { useEffect, useRef, useState } from "react";
import "./Footer.css";
import { Facebook, Instagram, Linkedin, TwitterX } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useGetParentCategoryInfoQuery } from "../../../RTK/Api/CategoryApi";
import { toast } from "react-toastify";
import { useGetCmsPagesQuery } from "../../../RTK/Api/CMSApi";
import TransHook from "./../../../hooks/translation/trans-hook";

const Footer = () => {
  const navigate = useNavigate();

  const { data: CMSData, error: cmsError } = useGetCmsPagesQuery();
  if (cmsError) toast.error(cmsError);

  const { data: category, error: categoryError } =
    useGetParentCategoryInfoQuery();
  if (categoryError) toast.error(categoryError);

  const emptyData = {
    placeholder: true,
    companyId: "660fab2cb74becb936dee560",
    companyEmail: "abdulrahman.judy0604@gmail.com",
    companyLogo:
      "https://nooncar.com:8003/companyinfo/company-9e4c6278-8994-462a-b49b-02245c748224-1717483800360.png",
    companyName: "kai",
    companyTax: "6081018629",
    companyTel: "05300000000",
    companyAddress:
      "36017 NOLU SK SAIT SAYIN IS MERKEZI No:NO: 30 27090 SEHITKAMIL/ Gaziantep GAZİKENT, Gaziantep",
    companyColors: [],
    facebookUrl: "profile.php?id=100088659776655",
    instagramUrl: "smartinb_solutions/",
    xtwitterUrl: "SmartinbX",
    linkedinUrl: "https://www.linkedin.com/company/smartinb-solutions",
  };

  let comData = Cookies.get("CompanyData")
    ? JSON.parse(Cookies.get("CompanyData"))
    : emptyData;

  const [ParentCategories, setParentCategories] = useState([]);

  useEffect(() => {
    setParentCategories(
      category?.data?.filter((category) => !category.parentCategory)
    );
  }, [category]);

  const handleButtonClick = (id) => {
    navigate(`/products/${id}/category`);
  };

  const { t } = TransHook();
  const lang = localStorage.getItem("language");

  return (
    <div className={`footer  ${lang === "ar" ? "arabic-text" : ""}`}>
      <div className="footer-middle container px-0 py-5">
        <div className="row px-5">
          {/* Company logo */}
          <div className="col-lg-2 col-md-6 m-auto">
            <Link to="/">
              <img
                src={comData?.companyLogo}
                alt={comData?.companyName}
                className="logo m-0 p-0 w-100 mx-auto"
              />
            </Link>
          </div>

          {/* Contact info & Social media */}
          <div className="col-lg-3 col-md-6">
            <div className="row m-0">
              <div className="contact-widget phone">
                <h4 className="widget-title">{t("callUsNow")}:</h4>
                <Link to={`tel:${comData?.companyTel}`}>
                  {comData?.companyTel}
                </Link>
              </div>
            </div>
            <div className="row m-0">
              <div className="contact-widget email">
                <h4 className="widget-title">{t("email")}:</h4>
                <Link to={`mailto:${comData?.companyEmail}`}>
                  {comData?.companyEmail}
                </Link>
              </div>
            </div>
            <div className="row m-0">
              <div className="contact-widget follow">
                <h4 className="widget-title ls-n-10">{t("followUs")}</h4>
                <div className="social-icons">
                  <Link
                    to={`https://instagram.com/${
                      comData?.instagramUrl !== undefined
                        ? comData?.instagramUrl
                        : "smartinb_solutions"
                    }`}
                    className="social-icon social-instagram"
                    title="Instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Instagram color="#379397" />
                  </Link>
                  <Link
                    to={`https://facebook.com/${
                      comData?.facebookUrl !== undefined
                        ? comData?.facebookUrl
                        : "profile.php?id=100088659776655"
                    }`}
                    className="social-icon social-facebook"
                    title="Facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Facebook color="#379397" />
                  </Link>
                  <Link
                    to={`https://x.com/${
                      comData?.xtwitterUrl !== undefined
                        ? comData?.xtwitterUrl
                        : "smartinbX"
                    }`}
                    title="X (Twitter)"
                    className="social-icon social-twitter"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TwitterX color="#379397" />
                  </Link>
                  <Link
                    to={comData?.linkedinUrl}
                    className="social-icon social-linkedin fab fa-linkedin-in"
                    target="_blank"
                    title="LinkedIn"
                    rel="noreferrer"
                  >
                    <Linkedin color="#379397" />
                  </Link>
                </div>
                {/* <!-- End .social-icons --> */}
              </div>
            </div>
          </div>

          {/* Categories */}
          <div className="col-lg-2 col-md-6">
            <div className="widget">
              <h4 className="widget-title">{t("categories")}</h4>

              <ul className="links">
                {ParentCategories?.slice(0, 5).map((cat, i) => {
                  return (
                    <li key={i}>
                      <span
                        onClick={() => handleButtonClick(cat._id)}
                        style={{ color: "#777", cursor: "pointer" }}
                      >
                        {cat.name}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          {/* About */}
          <div className="col-lg-2 col-md-6">
            <div className="widget">
              <h4 className="widget-title">{t("about")}</h4>

              <ul className="links">
                {/* <li>
                  <a href="/">About us</a>
                </li>
                <li>
                  <a href="/">Contact us</a>
                </li> */}
                {CMSData?.data &&
                  CMSData?.data?.map((item, i) => {
                    return (
                      <li key={i}>
                        <span
                          style={{ cursor: "pointer", color: "#777" }}
                          onClick={() => navigate(`/CMS/${item._id}`)}
                        >
                          {item.name}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>

          {/* Customer care */}
          <div className="col-lg-2 col-md-6">
            <div className="widget">
              <h4 className="widget-title">{t("customerCare")}</h4>
              <ul className="links">
                <li>
                  <Link to="/account-details">{t("myAccount")}</Link>
                </li>
                <li>
                  <Link to="#">{t("termsConditions")}</Link>
                </li>
                <li>
                  <Link to="#">{t("faq")}</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom d-sm-flex align-items-center">
        <div className="footer-left">
          <span className="footer-copyright">
            ©{" "}
            <Link to="https://smartinb.com" target="_blank">
              <b>Smartinb</b>
            </Link>{" "}
            2024. {t("allRightsReserved")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
