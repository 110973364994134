import { useEffect, useState } from "react";
import Cookies from "js-cookie";

const SetColors = () => {
  const [colors, setColors] = useState([]);
  const CompanyData = Cookies.get("CompanyData");
  // get colors
  useEffect(() => {
    if (CompanyData) {
      const JsonData = JSON.parse(CompanyData);
      setColors(JsonData?.color);
    }
  }, [CompanyData]);

  //set colors to css classes
  //useEffect(() => {
  //  document.documentElement.style.setProperty("--primary-color", colors[0]);
  //  document.documentElement.style.setProperty("--secondary-color", colors[1]);
  //}, [colors]);
};

export default SetColors;
