import React, { useState } from "react";
import AddressCard from "./AddressCard";
import { CreditCard, Plus, Whatsapp } from "react-bootstrap-icons";
import "./Checkout.css";
import CheckoutHook from "./../../hooks/cart/CheckoutHook";
import BankTransfer from "../../assets/js/BankTransfer";
import TransHook from "../../hooks/translation/trans-hook";

const Checkout = ({ goBackToCart, cartId }) => {
  const {
    addressGet,
    activeShipIndex,
    activeBilIndex,
    addingAddress,
    alias,
    firstName,
    lastName,
    city,
    details,
    postalCode,
    phone,
    cart,
    differentBilling,
    handleAliasChange,
    handleFirstNameChange,
    handleLastNameChange,
    handleCityChange,
    handleDetailsChange,
    handlePostalCodeChange,
    handlePhoneChange,
    save,
    cancel,
    createOrder,
    setDifferentBilling,
    setAddingAddress,
    selectShipAddress,
    selectBilAddress,
  } = CheckoutHook(cartId);

  const [paymentMethod, setPaymentMethod] = useState("creditCard");

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const { t } = TransHook();
  const lang = localStorage.getItem("language");

  return (
    <>
      <div
        className={`row ${lang === "ar" ? "arabic-text" : ""}`}
        style={{ justifyContent: "space-around" }}
      >
        <div className="col-lg-7">
          <ul className="checkout-steps">
            <li>
              <h2 className="step-title">{t("shippingDetails")}</h2>

              <div className="d-flex flex-wrap Adresses">
                {addressGet && addressGet?.data?.length > 0 ? (
                  addressGet?.data?.map((item, i) => {
                    const isActive = activeShipIndex === i;
                    return (
                      <div
                        className={`col-md-6 mb-3`}
                        key={i}
                        onClick={() => selectShipAddress(i, item)}
                      >
                        <AddressCard
                          alias={item.alias}
                          name={item.name}
                          phone={item.phone}
                          details={item.details}
                          city={item.city}
                          postalCode={item.postalCode}
                          isActive={isActive}
                        />
                      </div>
                    );
                  })
                ) : (
                  <h3>{t("noAddresses")}</h3>
                )}
              </div>

              {/* Different billing START */}
              <div className="form-group">
                <div
                  className="custom-control custom-checkbox mt-0"
                  onClick={() => setDifferentBilling(!differentBilling)}
                >
                  <input
                    type="checkbox"
                    checked={differentBilling}
                    className="custom-control-input"
                    id="different-shipping"
                  />
                  <label className="custom-control-label">
                    {t("differentBilling")}
                  </label>
                </div>
              </div>
              {/* Different billing END */}
              {differentBilling ? (
                <div
                  className="d-flex flex-wrap"
                  style={{ justifyContent: "unset" }}
                >
                  {addressGet && addressGet?.data?.length > 0 ? (
                    addressGet?.data?.map((item, i) => {
                      const isActive = activeBilIndex === i;
                      return (
                        <div
                          className={`col-md-6 mb-3`}
                          key={i}
                          onClick={() => selectBilAddress(i, item)}
                        >
                          <AddressCard
                            alias={item.alias}
                            name={item.firstName + " " + item.lastName}
                            phone={item.phone}
                            details={item.details}
                            city={item.city}
                            postalCode={item.postalCode}
                            isActive={isActive}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <h3>{t("differentBilling")}</h3>
                  )}
                </div>
              ) : null}
              <div
                className="address-card col-md-12 text-center mb-4"
                onClick={() => setAddingAddress(!addingAddress)}
              >
                <Plus size={50} />
                <h3>{t("addNewAddress")}</h3>
              </div>

              {/* Address form START */}
              <form
                action="#"
                id="checkout-form"
                className={addingAddress ? "" : "collapse"}
              >
                <div className="address account-content mt-0 pt-2">
                  <h4 className="title">{t("addNewAddress")}</h4>

                  <form className="mb-2" action="#">
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label>
                          {t("addressTitleHomeWork")}{" "}
                          <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("home")}
                          required
                          value={alias}
                          onChange={handleAliasChange}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            {t("firstName")} <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={firstName}
                            onChange={handleFirstNameChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            {t("lastName")} <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={lastName}
                            onChange={handleLastNameChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>
                          {t("townCity")} <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={city}
                          onChange={handleCityChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          {t("address")} <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={details}
                          onChange={handleDetailsChange}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>
                          {t("postalCode")} <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={postalCode}
                          onChange={handlePostalCodeChange}
                        />
                      </div>

                      <div className="form-group col-md-6 mb-3">
                        <label>
                          {t("phoneNumber")} <span className="required">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          required
                          value={phone}
                          onChange={handlePhoneChange}
                        />
                      </div>
                    </div>

                    <div className="form-footer mb-0">
                      <div className="form-footer-right">
                        <span
                          className="btn btn-dark py-4"
                          onClick={() => save()}
                        >
                          {t("save")}
                        </span>
                        <button
                          type="button"
                          className="btn btn-dark py-4"
                          onClick={() => cancel()}
                        >
                          {t("cancel")}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                {/* Contact END */}
              </form>
              {/* Address form END */}
            </li>
          </ul>
        </div>

        {/* Order summary START */}
        <div className="col-lg-4">
          <div className="order-summary">
            <h3>{t("yourOrder")}</h3>

            <table className="table table-mini-cart">
              <thead>
                <tr>
                  <th colspan="2">{t("product")}</th>
                </tr>
              </thead>
              <tbody>
                {cart?.cartItems?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td className="product-col">
                        <h3 className="product-title">
                          {item.name}&nbsp;×
                          <span className="product-qty">{item.quantity}</span>
                        </h3>
                      </td>

                      <td className="price-col">
                        <span>
                          ${(item.taxPrice * item.quantity).toFixed(3)}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr className="order-total">
                  <td>
                    <h4>{t("total")}</h4>
                  </td>
                  <td>
                    <b className="total-price">
                      <span>${cart?.totalCartPrice.toFixed(3)}</span>
                    </b>
                  </td>
                </tr>
              </tfoot>
            </table>

            <div className="payment-methods">
              <h4 className="">{t("paymentMethods")}</h4>
              <div className="info-box with-icon p-0">
                <ul>
                  <li>
                    <label
                      style={{ fontWeight: "normal", fontSize: "14px" }}
                      className="cursor-pointer"
                    >
                      <input
                        type="radio"
                        name="paymentMethod"
                        value="creditCard"
                        checked={paymentMethod === "creditCard"}
                        onChange={handlePaymentMethodChange}
                      />
                      &nbsp;{t("creditCard")} <CreditCard />
                    </label>
                  </li>
                  <li>
                    <label
                      style={{ fontWeight: "normal", fontSize: "14px" }}
                      className="cursor-pointer"
                    >
                      <input
                        type="radio"
                        name="paymentMethod"
                        value="eftTransfer"
                        checked={paymentMethod === "eftTransfer"}
                        onChange={handlePaymentMethodChange}
                      />
                      &nbsp;{t("eftTransfer")} <BankTransfer />
                    </label>
                  </li>
                  <li>
                    <label
                      style={{ fontWeight: "normal", fontSize: "14px" }}
                      className="cursor-pointer"
                    >
                      <input
                        type="radio"
                        name="paymentMethod"
                        value="whatsapp"
                        checked={paymentMethod === "whatsapp"}
                        onChange={handlePaymentMethodChange}
                      />
                      &nbsp;{t("whatsapp")} <Whatsapp />
                    </label>
                  </li>
                </ul>
              </div>
            </div>

            <span
              className="w-50 btn btn-dark btn-place-order"
              onClick={goBackToCart}
            >
              {t("backToCart")}
            </span>
            <span
              className="w-50 btn btn-dark btn-place-order"
              onClick={() => createOrder(paymentMethod)}
            >
              {t("placeOrder")}
            </span>
          </div>
        </div>
        {/* Order summary END */}
      </div>
    </>
  );
};

export default Checkout;
