import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import useGetData from "../api/useGetData";
import { LazyProductEndPoint } from "../../api/GlobalData";

const SearchHook = () => {
  const navigate = useNavigate();
  // States
  const [SearchKey, setSearchKey] = useState("");
  const [recent_keyword, set_recent_keyword] = useState([]);
  const [show_Recent_list, set_show_Recent_list] = useState(true);
  const [toggle_list, settoggle_list] = useState(false);
  const [isClicking, setIsClicking] = useState(false);

  // Load recent keywords from cookies on component mount
  useEffect(() => {
    const storedKeywords = Cookies.get("recent_keywords");
    if (storedKeywords) {
      set_recent_keyword(JSON.parse(storedKeywords));
    }
  }, []);

  // Function to add a new keyword to recent_keyword List
  const addKeyword = (keyword) => {
    const updatedKeywords = [
      keyword,
      ...recent_keyword.filter((Newk) => Newk !== keyword),
    ];
    const keywordsToSave = updatedKeywords.slice(0, 6);
    set_recent_keyword(keywordsToSave);
    Cookies.set("recent_keywords", JSON.stringify(keywordsToSave));
  };

  const handleMouseDown = () => setIsClicking(true);
  const handleMouseUp = () => setTimeout(() => setIsClicking(false), 0);

  // Handle navigate to product details
  const handleNavigate = (id) => {
    navigate(`/product-details/${id}`);
    settoggle_list(false);
  };

  // Event handler for the input field
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.value.trim() !== "") {
      addKeyword(event.target.value.trim());
      navigate(`/products/${event.target.value.trim()}`);
      setSearchKey(""); // Clear the input field after adding the keyword
    }
  };

  // Event handler for keyword click
  const handleKeywordClick = (keyword) => {
    setSearchKey(keyword);
    set_show_Recent_list(false);
    navigate(`/products/${keyword}`);
  };

  // Get the product data
  const { data: ProductResults, refetch } = useGetData(LazyProductEndPoint, {
    keyword: SearchKey,
  });

  // Make sure that the keyword is not empty to fetch data
  useEffect(() => {
    if (SearchKey.length > 0) {
      refetch();
    } else {
      set_show_Recent_list(true);
    }
  }, [SearchKey, refetch]);

  return {
    handleMouseDown,
    handleMouseUp,
    setSearchKey,
    set_show_Recent_list,
    handleKeyDown,
    settoggle_list,
    handleNavigate,
    handleKeywordClick,
    isClicking,
    SearchKey,
    show_Recent_list,
    ProductResults,
    recent_keyword,
    toggle_list,
  };
};

export default SearchHook;
