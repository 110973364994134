import React from "react";
import { useNavigate } from "react-router-dom";
import "./Slider.css";

const CatSliderNoImg = ({ Array: categories, limit }) => {
  const navigate = useNavigate();
  const handleButtonClick = (id) => {
    navigate(`/products/${id}/category`);
  };

  return (
    <div className="CatSliderNoImg">
      <ul className="d-flex">
        {categories?.slice(0, limit).map((item, index) => (
          <li
            key={index}
            className="nav-item  "
            onClick={() => handleButtonClick(item?._id)}
          >
            {item.name}

            {/* {item?.children?.length > 0 && (
            <div
              className="dropdown-menu"
              aria-labelledby={`navbarDropdownMenuLink${item._id}`}
            >
              {item.children.map((subcategory) => (
                <span
                  key={subcategory._id}
                  className="dropdown-item cursor-pointer"
                >
                  {subcategory.name}
                  {subcategory.children && (
                    <ul className="subCat">
                      {subcategory.children.map((child) => (
                        <li key={child._id}>{child.name}</li>
                      ))}
                    </ul>
                  )}
                </span>
              ))}
            </div>
          )} */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CatSliderNoImg;
