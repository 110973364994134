import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../utility/Slider/Slider.css";
import { useGetParentCategoryInfoQuery } from "../../RTK/Api/CategoryApi";
import { toast } from "react-toastify";
import TransHook from "../../hooks/translation/trans-hook";

const HomeCategories = () => {
  const navigate = useNavigate();
  const handleButtonClick = (id) => {
    navigate(`/products/${id}/category`);
  };

  const [ParentCategories, setParentCategories] = useState([]);
  const { data: category, error: categoryError } =
    useGetParentCategoryInfoQuery();
  if (categoryError) toast.error(categoryError);
  useEffect(() => {
    setParentCategories(
      category?.data?.filter((category) => !category.parentCategory)
    );
  }, [category]);
  const screen = localStorage.getItem("screen") || "large";
  const { t } = TransHook();
  const lang = localStorage.getItem("language");

  if (ParentCategories?.length > 0) {
    return (
      <div className="Home-Category">
        <h6
          className={`section-title ls-n-10 pb-3 m-b-4  ${
            lang === "ar" ? "arabic-text" : ""
          }`}
        >
          {t("discoverOurCategories")}
        </h6>
        <div className="Categories d-flex">
          {ParentCategories && (
            <div className="main_cat col-5">
              <div className="Cat_img">
                <img
                  src={
                    ParentCategories[0]?.image
                      ? ParentCategories[0]?.image
                      : "https://i1.wp.com/gelatologia.com/wp-content/uploads/2020/07/placeholder.png"
                  }
                  alt="CategoryImg"
                  onClick={() => handleButtonClick(ParentCategories[0]?._id)}
                />
              </div>
              <div className="Cat_info">
                <h6>{ParentCategories[0]?.name}</h6>
              </div>
            </div>
          )}

          <div className="sub_cats col-7 ">
            {ParentCategories?.slice(1, 7).map((item, index) => (
              <div key={index} className=" sub_cat d-flex">
                <img
                  className="horizontalImg border-10"
                  src={
                    item?.image
                      ? item?.image
                      : "https://i1.wp.com/gelatologia.com/wp-content/uploads/2020/07/placeholder.png"
                  }
                  alt="CategoryImg"
                  onClick={() => handleButtonClick(item?._id)}
                />
                <h4>{item?.name}</h4>
              </div>
            ))}
          </div>
        </div>

        {/* <swiper-container
          slides-per-view={screen === "large" ? 6 : 2}
          speed="500"
          loop="true"
        >
          {ParentCategories?.map((item) => (
            <swiper-slide key={item?._id} className="swiperSlide">
              <div className="Cat-info">
                <img
                  className="horizontalImg border-10"
                  src={
                    item?.image
                      ? item?.image
                      : "https://i1.wp.com/gelatologia.com/wp-content/uploads/2020/07/placeholder.png"
                  }
                  alt="CategoryImg"
                  onClick={() => handleButtonClick(item?._id)}
                />
                <h4>{item?.name}</h4>
              </div>
            </swiper-slide>
          ))}
        </swiper-container> */}
      </div>
    );
  }
};

export default HomeCategories;
