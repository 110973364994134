import React, { useEffect, useState } from "react";
import "./Slider.css";
import ProductCard from "../../Product/JSX/ProductCard";

const ProductSlider = ({ Array, slidesPerView }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (Array?.length > 0) setLoading(false);
    else {
      setLoading(true);
    }
  }, [Array]);

  return (
    <div className="ProductSlider">
      <swiper-container slides-per-view={slidesPerView}>
        {Array
          ? Array.map((item, index) => (
              <swiper-slide key={item._id}>
                <ProductCard product={item} key={index} />
              </swiper-slide>
            ))
          : null}
      </swiper-container>
    </div>
  );
};

export default ProductSlider;
