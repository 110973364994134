import React from "react";
import GetAllBrands from "../../hooks/Brands/GetAllBrands";
import { useNavigate } from "react-router-dom";
import "../utility/Slider/Slider.css";
import TransHook from "./../../hooks/translation/trans-hook";

const Brands = () => {
  const navigate = useNavigate();
  const [Brands] = GetAllBrands();

  const handleButtonClick = (id) => {
    navigate(`/products/${id}/brand`);
  };
  const screen = localStorage.getItem("screen") || "large";

  const { t } = TransHook();
  const lang = localStorage.getItem("language");

  if (Brands?.length > 0) {
    return (
      <div className="Home-Brands">
        <h2
          className={` section-title ls-n-10 pb-3 m-b-4  ${
            lang === "ar" ? "arabic-text" : ""
          }`}
        >
          {t("our Brands")}
        </h2>
        <div className="Brands">
          <swiper-container
            slides-per-view={screen === "large" ? 6 : 2}
            speed="500"
            loop="true"
          >
            {Brands?.map((item, index) => (
              <swiper-slide key={item._id} className="swiperSlide">
                <div className="Cat-info " key={index}>
                  <img
                    className="horizontalImg border-10"
                    src={item?.image ? item?.image : null}
                    alt={item?.name + " logo"}
                    onClick={() => handleButtonClick(item?._id)}
                  />
                  {/* <h3>{item.name}</h3> */}
                </div>
              </swiper-slide>
            ))}
          </swiper-container>
        </div>
      </div>
    );
  }
};

export default Brands;
