import React, { useEffect, useState } from "react";
import "./SideNav.css";
import Cookies from "js-cookie";
import TransHook from "./../../../hooks/translation/trans-hook";
import StarRatings from "react-star-ratings";
import CategoryTree from "../CategoryTree/CategoryTree ";
import "../CategoryTree/CategoryTree.css";

const SideNav = ({
  categories,
  brands,
  products,
  setBrandsArray,
  setMaxPrice,
  setMinPrice,
  setMaxAvg,
  setMinAvg,
  selectedBrands,
  setSelectedBrands,
  setSelectedBrandNames,
  selectedPriceRange,
  setSelectedPriceRange,
  ratingRange,
  setRatingRange,
}) => {
  const categoryArray = Array.isArray(categories) ? categories : [categories];
  const { t } = TransHook();
  const lang = localStorage.getItem("language");
  const cookieValue = Cookies.get("CompanyCurrency");
  const currency = cookieValue ? JSON.parse(cookieValue) : null;

  const RatingStars = [
    { min: 0, max: 1 },
    { min: 1, max: 2 },
    { min: 2, max: 3 },
    { min: 3, max: 4 },
    { min: 4, max: 5 },
  ];

  const [priceRanges, setPriceRanges] = useState([]);

  useEffect(() => {
    if (products) {
      getPriceRanges();
    }
  }, [products]);
  useEffect(() => {
    if (selectedBrands) {
      setBrandsArray(selectedBrands);
    }
  }, [selectedBrands]);
  useEffect(() => {
    if (selectedPriceRange) {
      setMinPrice(selectedPriceRange?.from);
      setMaxPrice(selectedPriceRange?.to);
    }
  }, [selectedPriceRange]);
  useEffect(() => {
    if (ratingRange) {
      setMinAvg(ratingRange?.rateMin);
      setMaxAvg(ratingRange?.rateMax);
    }
  }, [ratingRange]);
  const getPriceRanges = () => {
    if (priceRanges.length === 0 && products && products.length > 0) {
      const prices = products.map((product) =>
        product.ecommercePriceAftereDiscount > 0
          ? product.ecommercePriceAftereDiscount
          : product.ecommercePrice
      );

      const minPrice = Math.min(...prices.filter((price) => price > 0));
      const maxPrice = Math.max(...prices);
      const rangeSize = (maxPrice - minPrice) / 5;

      const priceRanges = [];
      for (let i = 0; i < 5; i++) {
        priceRanges.push({
          from: minPrice + i * rangeSize,
          to: i < 4 ? minPrice + (i + 1) * rangeSize : maxPrice,
        });
      }

      setPriceRanges(priceRanges);
    }
  };

  const handleBrandChange = (e) => {
    const value = JSON.parse(e.target.value);

    setSelectedBrands((prev) =>
      prev.includes(value.id)
        ? prev.filter((id) => id !== value.id)
        : [...prev, value.id]
    );

    setSelectedBrandNames((prev) =>
      prev.includes(value.name)
        ? prev.filter((name) => name !== value.name)
        : [...prev, value.name]
    );
  };

  const handleRatingChange = (min, max) => {
    setRatingRange({ rateMin: min, rateMax: max });
  };
  const handlePriceRangeChange = (from, to) => {
    setSelectedPriceRange({ from, to });
  };

  return (
    <aside
      className={`sidebar-shop col-lg-3 order-lg-first mobile-sidebar ${
        lang === "ar" ? "arabic-text" : ""
      }`}
    >
      <div className="sidebar-wrapper">
        {/* Category filtering START */}
        <div className="side-nav-filter-section-container">
          <CategoryTree categories={categoryArray} />
        </div>
        {/* Category filtering END */}

        {/* Brands filtering START */}
        <div className="side-nav-filter-section-container">
          <h3 className="side-nav-filter-title">{t("brands")}</h3>
          <div className="Brands_list">
            {brands?.map((brand, i) => (
              <div key={i}>
                <input
                  type="checkbox"
                  value={JSON.stringify(brand)}
                  onChange={handleBrandChange}
                />
                {brand?.name}
              </div>
            ))}
          </div>
        </div>
        {/* Brands filtering END */}

        {/* Price filtering START */}
        <div className="side-nav-filter-section-container">
          <h3 className="side-nav-filter-title">{t("price")}</h3>
          <form
            action="#"
            className="price_form"
            style={{ marginBottom: "0" }}
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              type="number"
              placeholder="min"
              value={selectedPriceRange.from || ""}
              min={0}
              onChange={(e) =>
                handlePriceRangeChange(
                  parseFloat(e.target.value),
                  selectedPriceRange.to
                )
              }
            />
            <input
              type="number"
              placeholder="max"
              value={selectedPriceRange.to || ""}
              min={parseFloat(selectedPriceRange.from) + 1}
              onChange={(e) =>
                handlePriceRangeChange(
                  selectedPriceRange.from,
                  parseFloat(e.target.value)
                )
              }
            />
          </form>
          <div className="price_ranges">
            {priceRanges.map((range, index) => (
              <div key={index}>
                <input
                  type="radio"
                  name="priceRange"
                  value={`${range.from}-${range.to}`}
                  onChange={() => handlePriceRangeChange(range.from, range.to)}
                  checked={
                    selectedPriceRange.from === range.from &&
                    selectedPriceRange.to === range.to
                  }
                />
                {range.from} {currency?.currencyCode} - {range.to}{" "}
                {currency?.currencyCode}
              </div>
            ))}
          </div>
        </div>
        {/* Price filtering END */}

        {/* Rating filtering START */}
        <div className="side-nav-filter-section-container">
          <h3 className="side-nav-filter-title">{t("Rating")}</h3>
          <div className="Brands_list">
            {RatingStars.map((range, i) => (
              <div key={i}>
                <input
                  type="radio"
                  value={`${range.min}-${range.max}`}
                  checked={
                    ratingRange.rateMin === range.min &&
                    ratingRange.rateMax === range.max
                  }
                  onChange={() => handleRatingChange(range.min, range.max)}
                />
                <div className="ratings-container">
                  <StarRatings
                    rating={range.min} // Display the max number of stars
                    starRatedColor="#fe9900"
                    starEmptyColor="lightgray"
                    numberOfStars={5}
                    starDimension="15px"
                    starSpacing=".5px"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Rating filtering END */}
      </div>
    </aside>
  );
};

export default SideNav;
