import React from "react";
import ProductSlider from "../utility/Slider/Product-Slider";
import {
  useGetEActiveProductsQuery,
  useGetProductsQuery,
} from "../../RTK/Api/ProductApi";
import { toast } from "react-toastify";
import TransHook from "../../hooks/translation/trans-hook";

const HomeNewArrivals = () => {
  const { data: NewProducts, error } = useGetProductsQuery({
    limit: "10",
  });
  if (error) toast.error(error);
  const screen = localStorage.getItem("screen") || "large";

  const { t } = TransHook();
  const lang = localStorage.getItem("language");
  console.log(NewProducts);
  return (
    <div className="ProductsNewArrivals">
      <h2
        className={`section-title ls-n-10 pb-3 m-b-4  ${
          lang === "ar" ? "arabic-text" : ""
        }`}
      >
        {t("newArrivals")}
      </h2>
      <div className="row new-products">
        <ProductSlider
          slidesPerView={screen === "large" ? 4 : 2}
          Array={NewProducts?.data}
        />
      </div>
    </div>
  );
};

export default HomeNewArrivals;
