import React, { useEffect, useState } from "react";
import axios from "axios";
import baseURL from "../../api/GlobalData";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import TransHook from "./../../hooks/translation/trans-hook";

const PaymentPage = () => {
  const [signedIn] = useState(!!Cookies.get("userData"));
  const [userInfo, setUserInfo] = useState({});
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [order, setOrder] = useState({});

  const orderString = localStorage.getItem("order");
  useEffect(() => {
    if (orderString) {
      const orderObj = JSON.parse(orderString);
      setOrder(orderObj);
      console.info(orderObj);
    }
  }, [orderString]);

  useEffect(() => {
    if (signedIn) {
      const userDataString = Cookies.get("userData");
      const userData = JSON.parse(userDataString);
      setUserInfo(userData);
    }
  }, [signedIn]);

  useEffect(() => {
    setUserEmail(userInfo?.email);
    setUserName(userInfo?.name);
  }, [userInfo]);

  const getIP = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP:", error);
      return null;
    }
  };

  const [iframeToken, setIframeToken] = useState("");

  const { t } = TransHook();
  useEffect(() => {
    const fetchToken = async () => {
      const user_ip = await getIP();
      const email = userEmail;
      const payment_amount = order?.data?.totalOrderPrice * 100;
      const user_name = userName;
      const user_address = `${order?.data?.shippingAddress?.details} ${order?.data?.shippingAddress?.city}`;
      const user_phone = order?.data?.shippingAddress?.phone;

      if (
        !email ||
        !payment_amount ||
        !user_ip ||
        !user_name ||
        !user_address ||
        !user_phone
      ) {
        toast.error(t("emptyFields"));
        return;
      }

      try {
        const response = await axios.post(`${baseURL}/api/paytr-token`, {
          email,
          payment_amount,
          user_ip,
          user_name,
          user_address,
          user_phone,
          order,
        });

        if (response.data.token) {
          setIframeToken(response.data.token);
        } else {
          console.error("Failed to get iFrame token");
        }
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };

    fetchToken();
  }, [userEmail, order]);

  return (
    <div>
      {iframeToken ? (
        <iframe
          src={`https://www.paytr.com/odeme/guvenli/${iframeToken}`}
          frameBorder="0"
          style={{ width: "100%", height: "600px" }}
        ></iframe>
      ) : (
        <p>Loading payment...</p>
      )}
    </div>
  );
};

export default PaymentPage;
