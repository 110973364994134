import { useMutation } from "react-query";
import baseURL from "../../api/GlobalData";
import { DataBaseName } from "../../api/GlobalData";
import Cookies from "js-cookie";

const usePostData = (endpoint, params = {}, onSuccess) => {
  const fixedParams = { databaseName: `${DataBaseName}`, ...params };
  const queryParams = new URLSearchParams(fixedParams).toString();
  const url = `${baseURL}${endpoint}${queryParams ? `?${queryParams}` : ""}`;
  const jwt = Cookies.get("Token");

  const postData = async (data) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error("Error from server: " + (await response.text()));
      }
      return response.json();
    } catch (error) {
      console.error("Error during fetch:", error);
      throw error;
    }
  };

  const mutation = useMutation(postData, {
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
      console.log("Data from server:", data); // TODO: Remove before publishing
    },
    onError: (error) => {
      console.error("Error from useMutation:", error);
    },
  });

  // Return the entire mutation object for access to data, error, and loading state
  return mutation;
};

export default usePostData;
