import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseURL, {
  ThirdPartyAuthEndPoint,
  DataBaseName,
} from "../../api/GlobalData";

export const thirdPartyAuthApi = createApi({
  reducerPath: "thirdPartyAuthApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
  }),
  tagTypes: ["ThirdPartyAuth"],
  endpoints: (builder) => ({
    getThirdPartyAuth: builder.query({
      query: () => `${ThirdPartyAuthEndPoint}?databaseName=${DataBaseName}`,
      providesTags: ["ThirdPartyAuth"],
    }),
  }),
});

export const { useGetThirdPartyAuthQuery } = thirdPartyAuthApi;
