import React, { useEffect, useState } from "react";
import "./navbar-mobile.css";
import NavBarHook from "../../../hooks/HomePage/NavBarHook";
import { Link, useNavigate } from "react-router-dom";
import { useGetCartInfoQuery } from "../../../RTK/Api/CartApi";
import { useGetWishlistInfoQuery } from "../../../RTK/Api/WishlistApi";
import { toast } from "react-toastify";
import { Cart, Heart, List, Person, X } from "react-bootstrap-icons";
import { useGetParentCategoryInfoQuery } from "../../../RTK/Api/CategoryApi";
import SearchBar from "../Search/SearchBar";
import TransHook from "../../../hooks/translation/trans-hook";

const NavbarMobile = () => {
  const navigate = useNavigate();
  const { signedIn, userInfo, companyLogo, LogOut } = NavBarHook();
  const { data: cartInfo, cartError } = useGetCartInfoQuery();
  if (cartError) toast.error(cartError);

  const { data: wishlistInfo, wishlistError } = useGetWishlistInfoQuery();
  if (wishlistError) toast.error(wishlistError);

  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNavbar = () => setIsNavVisible(!isNavVisible);

  const [ParentCategories, setParentCategories] = useState([]);
  const { data: category, error: categoryError } =
    useGetParentCategoryInfoQuery();
  if (categoryError) toast.error(categoryError);
  useEffect(() => {
    setParentCategories(
      category?.data?.filter((category) => !category.parentCategory)
    );
  }, [category]);

  const { t } = TransHook();

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <List size={28} style={{ margin: "5px" }} onClick={toggleNavbar} />
          <Link to="/" style={{ color: "#777" }}>
            <h3>Smart E-commerce</h3>
          </Link>
        </div>
        <div>
          <Link
            to="#"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <Person size={25} style={{ margin: "10px" }} color="#777" />
          </Link>
          {signedIn ? (
            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <span className="dropdown-item">{userInfo?.name}</span>
              <hr className="m-0" />
              <span
                className="dropdown-item cursor-pointer"
                onClick={() => navigate("/account-details")}
              >
                {t("myAccount")}
              </span>
              <span className="dropdown-item cursor-pointer" onClick={LogOut}>
                {t("logout")}
              </span>
            </div>
          ) : (
            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <span className="dropdown-item cursor-pointer">
                <Link to="/login" style={{ color: "#000" }}>
                  {t("signInRegister")}
                </Link>
              </span>
            </div>
          )}

          <Link
            to="/my-wishlist"
            className="header-icon position-relative m-0"
            title="wishlist"
            style={{ color: "#777" }}
          >
            <Heart size={20} style={{ margin: "10px" }} />
          </Link>
          <Link
            to="/cart"
            className="header-icon position-relative m-0"
            style={{ color: "#777" }}
          >
            <Cart size={25} style={{ margin: "10px" }} />
          </Link>
        </div>
      </div>

      <SearchBar />

      <div className={`side-menu ${isNavVisible ? "visible" : ""}`}>
        <div className="d-flex align-items-center justify-content-between">
          <h4>
            <Link to="/" style={{ color: "#777" }}>
              <b>Smart E-commerce</b>
            </Link>
          </h4>{" "}
          <X size={28} onClick={toggleNavbar} />
        </div>
        {ParentCategories &&
          ParentCategories.map((category, i) => {
            return (
              <Link to={`products/${category?._id}/category`} key={i}>
                {category?.name}
              </Link>
            );
          })}
      </div>
    </>
  );
};

export default NavbarMobile;
