import React, { useState } from "react";
import {
  GeoAlt,
  Pencil,
  PersonFill,
  Phone,
  Trash,
} from "react-bootstrap-icons";
import { Button, Modal } from "react-bootstrap";
import "./Checkout.css";
import AddressesHook from "../../hooks/profile/AddressesHook";
import TransHook from "./../../hooks/translation/trans-hook";

const AddressCard = ({
  alias,
  name,
  phone,
  city,
  details,
  postalCode,
  isActive,
  id,
  onEdit = null,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const handleCloseConfirm = () => setShowConfirm(false);

  const { deleteAddress } = AddressesHook();

  const handleAddressRemove = (id) => {
    deleteAddress(id);
    handleCloseConfirm();
  };

  const { t } = TransHook();
  const lang = localStorage.getItem("language");

  return (
    <div
      className={`address-card ${isActive ? "active-address-card" : ""} ${
        lang === "ar" ? "arabic-text" : ""
      }`}
    >
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <GeoAlt />
          &nbsp;{alias}
        </div>
        <div className="d-flex">
          {onEdit !== null ? <Pencil onClick={() => onEdit(id)} /> : null}
          &nbsp;
          <Trash onClick={() => setShowConfirm(true)} />
        </div>
      </div>
      <hr className="m-0 mb-1" />
      <div className="d-flex my-2" style={{ justifyContent: "space-between" }}>
        <div className="d-flex" style={{ alignItems: "center" }}>
          <PersonFill />
          &nbsp;{name}
        </div>
        <div className="d-flex" style={{ alignItems: "center" }}>
          <Phone />
          &nbsp;{phone}
        </div>
      </div>
      <p className="my-2">
        {details?.length > 50 ? details.slice(0, 50) + "..." : details}
      </p>
      <h5>
        {city} - {postalCode}
      </h5>
      <Modal show={showConfirm} onHide={handleCloseConfirm}>
        <Modal.Header>
          <Modal.Title>{t("caution")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>{t("addressDeleteConfirm")}</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirm}>
            {t("cancel")}
          </Button>
          <Button variant="primary" onClick={() => handleAddressRemove(id)}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddressCard;
