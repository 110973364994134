import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import dataAr from "../../translations/Ar.json";
import dataEn from "../../translations/En.json";
import dataTr from "../../translations/Tr.json";

const resources = {
  ar: {
    translation: dataAr,
  },
  en: {
    translation: dataEn,
  },
  tr: {
    translation: dataTr,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language"),

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
