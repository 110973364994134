import { useQuery } from "react-query";
import baseURL, { DataBaseName } from "../../api/GlobalData";
import Cookies from "js-cookie";

const useGetData = (endpoint, params = {}) => {
  const fixedParams = { databaseName: `${DataBaseName}`, ...params };
  const queryParams = new URLSearchParams(fixedParams).toString();
  const url = `${baseURL}${endpoint}${queryParams ? `?${queryParams}` : ""}`;
  const jwt = Cookies.get("Token");
  const getData = async () => {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (!response.ok) {
      console.error(response.statusText);
      return;
    }
    return response.json();
  };

  // const query = useQuery(["getData", endpoint, params], getData);
  const { data, isLoading, error, refetch } = useQuery(
    ["getData", endpoint, params],
    getData,
    {
      enabled: true,
    }
  );

  return { data, isLoading, error, refetch };
};

export default useGetData;
