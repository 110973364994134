import { LazyProductEndPoint } from "../../api/GlobalData";
import useGetData from "../api/useGetData";

const GetProductsHook = () => {
  const {
    isLoading: AllProducts_isLoading,
    data: AllProducts,
    error,
  } = useGetData(LazyProductEndPoint, { limit: 8 });

  return [AllProducts?.data, AllProducts_isLoading];
};

export default GetProductsHook;
