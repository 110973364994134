import React, { useEffect } from "react";
import AddressesHook from "../../hooks/profile/AddressesHook";
import { toast } from "react-toastify";
import TransHook from "../../hooks/translation/trans-hook";

const Shippings = ({ active, onCancel, editAddressId }) => {
  const {
    handleAliasChange,
    handleFirstNameChange,
    handleLastNameChange,
    handleCityChange,
    handleDetailsChange,
    handlePostalCodeChange,
    handlePhoneChange,
    alias,
    firstName,
    lastName,
    city,
    details,
    postalCode,
    phone,
    saveAddress,
    updateAddress,
    clearFields,
    getOneAddress,
  } = AddressesHook(onCancel);

  const { t } = TransHook();

  useEffect(() => {
    if (editAddressId) {
      getOneAddress(editAddressId)
        .then((data) => {
          handleAliasChange({ target: { value: data?.data?.alias } });
          handleFirstNameChange({ target: { value: data?.data?.firstName } });
          handleLastNameChange({ target: { value: data?.data?.lastName } });
          handleCityChange({ target: { value: data?.data?.city } });
          handleDetailsChange({ target: { value: data?.data?.details } });
          handlePostalCodeChange({ target: { value: data?.data?.postalCode } });
          handlePhoneChange({ target: { value: data?.data?.phone } });
        })
        .catch((error) => toast.error(`${t("failedToFetchData")} ${error}`));
    }
  }, [editAddressId]);

  const handleSubmit = () => {
    if (editAddressId) {
      updateAddress(editAddressId, {
        alias,
        firstName,
        lastName,
        city,
        details,
        postalCode,
        phone,
      });
    } else {
      saveAddress();
    }
  };

  return (
    <div
      className={`tab-pane fade ${active ? "active show" : ""}`}
      id="shipping"
      role="tabpanel"
    >
      <div className="address account-content mt-0 pt-2">
        <h4 className="title mb-3">
          {editAddressId ? t("editAddress") : t("addAddress")}
        </h4>

        <form className="mb-2" action="#">
          <div className="row">
            <div className="form-group mb-3 col-md-6">
              <label>
                {t("addressTitleHomeWork")} <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={alias}
                onChange={handleAliasChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  {t("firstName")} <span className="required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={firstName}
                  onChange={handleFirstNameChange}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>
                  {t("lastName")} <span className="required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={lastName}
                  onChange={handleLastNameChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <label>
                {t("townCity")} <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={city}
                onChange={handleCityChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>
                {t("address")} <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={details}
                onChange={handleDetailsChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <label>
                {t("postalCode")} <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={postalCode}
                onChange={handlePostalCodeChange}
              />
            </div>

            <div className="form-group col-md-6 mb-3">
              <label>
                {t("phoneNumber")} <span className="required">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                required
                value={phone}
                onChange={handlePhoneChange}
              />
            </div>
          </div>

          <div className="form-footer mb-0">
            <div className="form-footer-right">
              <span className="btn btn-dark py-4" onClick={handleSubmit}>
                {editAddressId ? t("update") : t("save")}
              </span>
              <button
                type="button"
                className="btn btn-dark py-4"
                onClick={onCancel}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Shippings;
