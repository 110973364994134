import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAddCartItemMutation } from "../../RTK/Api/CartApi";
import { useAddWishlistItemMutation } from "../../RTK/Api/WishlistApi";
import usePostData from "./../api/usePostData";
import { CartEndPoint } from "../../api/GlobalData";
import TransHook from "./../translation/trans-hook";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

const ProductCardHook = () => {
  const { t } = TransHook();
  const [addCartItem, { error: addItemError }] = useAddCartItemMutation();
  if (addItemError) toast.error(t("failedToAddToCart"));

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const data = Cookies.get("userData");
    if (data) {
      setUserData(JSON.parse(data));
    }
  }, []);

  const navigate = useNavigate();
  const mutation = usePostData(CartEndPoint);
  const handleCart = async (product) => {
    if (userData) {
      const ob = {
        qr: product?.qr,
        taxPrice:
          product?.ecommercePriceAftereDiscount > 0
            ? product?.ecommercePriceAftereDiscount
            : product?.ecommercePrice > 0
            ? product?.ecommercePrice
            : product?.taxPrice,
        quantity: 1,
        buyingPrice: product?.buyingprice,
        taxRate: product?.tax[0]?.tax,
        name: product?.name,
        price: product?.price,
      };
      await addCartItem(ob)
        .unwrap()
        .then(() => toast.success(t("addedToCart")))
        .catch((error) => toast.error(error));
    } else {
      toast.warn(t("pleaseLogin"));
    }
  };

  // Buy Now functionality
  const BuyNow = (product) => {
    if (userData) {
      if (product?.activeCount > 0) {
        const cartData = {
          qr: product?.qr || "",
          quantity: 1,
          taxPrice: product?.taxPrice || 0,
        };
        if (cartData) {
          mutation.mutate(cartData, {
            onSuccess: () => {
              navigate("/cart");
            },
          });
        }
      } else {
        toast.error(t("itemNotAvailable"));
      }
    } else {
      toast.warn(t("pleaseLogin"));
    }
  };

  // Add to WishList functionality
  const [addWishlistItem, { error: addError }] = useAddWishlistItemMutation();
  if (addError) toast.error(addError);

  const handleAddItem = async (itemId) => {
    if (userData) {
      try {
        await addWishlistItem({ productId: itemId })
          .unwrap()
          .then(() => toast.success(t("addedToWishlist")));
      } catch (error) {
        toast.error(error);
      }
    } else {
      toast.warn(t("pleaseLogin"));
    }
  };

  const screen = localStorage.getItem("screen") || "large";

  return { screen, handleCart, BuyNow, handleAddItem };
};

export default ProductCardHook;
