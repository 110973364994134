import { useEffect, useState } from "react";
import { BrandsEndPoint } from "../../api/GlobalData";
import useGetData from "../api/useGetData";
import { toast } from "react-toastify";

const GetAllBrands = () => {
  const [Brands, setBrands] = useState([]);
  const { data, error } = useGetData(BrandsEndPoint);
  if (error) toast.error(error);
  useEffect(() => {
    setBrands(data?.data);
  }, [data]);
  return [Brands];
};

export default GetAllBrands;
