import React from "react";
import Navbar from "../../components/utility/navbar/navbar";
import Footer from "../../components/utility/Footer/Footer";

const Error404 = () => {
  return (
    <>
      <Navbar />
      <div>
        <h1
          style={{
            fontSize: "200px",
            alignItems: "center",
            marginTop: "200px",
          }}
        >
          Error! 404.
        </h1>
      </div>
      <Footer />
    </>
  );
};

export default Error404;
