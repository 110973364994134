import React, { useEffect, useState } from "react";
import {
  BanFill,
  CheckCircleFill,
  Diagram2Fill,
  Dropbox,
  HourglassBottom,
  Truck,
  XCircleFill,
} from "react-bootstrap-icons";
import { useGetOrderInfoQuery } from "../../RTK/Api/OrderApi";
import OrderDetails from "./../../components/Order/OrderDetails";
import "./Account.css";
import TransHook from "../../hooks/translation/trans-hook";

const Orders = ({ active }) => {
  const { data } = useGetOrderInfoQuery();
  const { t } = TransHook();
  const [order, setOrder] = useState(null);

  const goToOrder = (item) => {
    setOrder(item);
  };

  const getStatus = (status) => {
    switch (status) {
      case "cancelled":
        return (
          <div className="d-flex w-25" style={{ alignItems: "center" }}>
            <XCircleFill color="#f06" />
            &nbsp; <b>{t("cancelled")}</b>
          </div>
        );
      case "requested":
        return (
          <div className="d-flex w-25" style={{ alignItems: "center" }}>
            <HourglassBottom color="#fa6" />
            &nbsp; <b>{t("requested")}</b>
          </div>
        );
      case "delivered":
        return (
          <div className="d-flex w-25" style={{ alignItems: "center" }}>
            <CheckCircleFill color="#00a500 " />
            &nbsp; <b>{t("delivered")}</b>
          </div>
        );
      case "processed":
        return (
          <div className="d-flex w-25" style={{ alignItems: "center" }}>
            <Diagram2Fill color="#0af" />
            &nbsp; <b>{t("processed")}</b>
          </div>
        );
      case "not delivered":
        return (
          <div className="d-flex w-25" style={{ alignItems: "center" }}>
            <BanFill color="#f00" />
            &nbsp; <b>{t("notDelivered")}</b>
          </div>
        );
      case "shipped":
        return (
          <div className="d-flex w-25" style={{ alignItems: "center" }}>
            <Truck color="#070" />
            &nbsp; <b>{t("shipped")}</b>
          </div>
        );
      default:
        return (
          <div className="d-flex w-25" style={{ alignItems: "center" }}>
            <HourglassBottom color="#fa6" />
            &nbsp; <b>{t("ongoing")}</b>
          </div>
        );
    }
  };

  const determineOrderStatus = (cartItems) => {
    const statuses = cartItems.map((item) => item.orderStatus);
    if (statuses.every((status) => status === "delivered")) {
      return "delivered";
    }
    if (statuses.every((status) => status === "cancelled")) {
      return "cancelled";
    }
    if (statuses.every((status) => status === "requested")) {
      return "requested";
    }
    if (statuses.every((status) => status === "processed")) {
      return "processed";
    }
    if (statuses.every((status) => status === "shipped")) {
      return "shipped";
    }
    if (statuses.every((status) => status === "not delivered")) {
      return "not delivered";
    }
    return "ongoing";
  };

  useEffect(() => {
    if (data?.data && data.data.length > 0) {
      const updatedOrderStatus = data.data.map((order) =>
        determineOrderStatus(order.cartItems)
      );
    }
  }, [data]);
  const lang = localStorage.getItem("language");

  return (
    <div
      className={`tab-pane fade ${active ? "active show" : ""} ${
        lang === "ar" ? "arabic-text" : ""
      }`}
      id="my-orders"
      role="tabpanel"
    >
      <div className="order-content">
        <h3 className="account-sub-title d-none d-md-block">
          <Dropbox size={40} className="align-middle mr-3 pr-1" /> {t("orders")}
        </h3>
        <div className="order-table-container text-center">
          {order ? (
            <OrderDetails order={order} setOrder={setOrder} />
          ) : (
            <>
              {data?.data && data?.data?.length > 0 ? (
                data?.data?.map((item, i) => {
                  const orderStatus = determineOrderStatus(item?.cartItems);
                  return (
                    <div
                      key={i}
                      className="order_card d-flex px-4 mb-2"
                      onClick={() => goToOrder(item)}
                    >
                      <div
                        className="d-flex w-25"
                        style={{ alignItems: "center" }}
                      >
                        {item?.cartItems?.slice(0, 2).map((product, j) => (
                          <img
                            key={j}
                            src={product?.product?.image}
                            alt={product?.product?.name?.split(" ")[0]}
                          />
                        ))}
                      </div>
                      <div
                        className="d-flex w-25"
                        style={{ alignItems: "center" }}
                      >
                        <p className="m-0">{t("orderNo")}:</p>&nbsp;
                        <b>{item?.orderNumber}</b>
                      </div>
                      {getStatus(orderStatus)}
                      <div style={{ alignItems: "center" }} className="w-25">
                        <p className="m-0">{item?.date?.substring(0, 10)}</p>
                        <b style={{ color: "#00aa00" }}>
                          {item?.totalOrderPrice.toFixed(3)}
                        </b>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h3>{t("noOrders")}</h3>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Orders;
